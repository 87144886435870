import * as i0 from '@angular/core';
import { InjectionToken, NgModule, Self } from '@angular/core';
import * as i1 from '@angular-ru/ngxs/internals';
import { NgxsDataFactory, NgxsDataInjector, NgxsDataSequence } from '@angular-ru/ngxs/internals';
const NGXS_DATA_CONFIG_DEFAULT = {
  dataActionSubscribeRequired: true
};
const NGXS_DATA_CONFIG = new InjectionToken('NGXS_DATA_CONFIG', {
  providedIn: 'root',
  factory: () => NGXS_DATA_CONFIG_DEFAULT
});
class NgxsDataPluginModule {
  constructor(accessor, injector) {
    this.accessor = accessor;
    this.injector = injector;
  }
  static forRoot(extensions = []) {
    return {
      ngModule: NgxsDataPluginModule,
      providers: [NgxsDataFactory, NgxsDataInjector, NgxsDataSequence, ...extensions]
    };
  }
}
/** @nocollapse */
NgxsDataPluginModule.ɵfac = function NgxsDataPluginModule_Factory(t) {
  return new (t || NgxsDataPluginModule)(i0.ɵɵinject(i1.NgxsDataFactory, 2), i0.ɵɵinject(i1.NgxsDataInjector, 2));
};
/** @nocollapse */
NgxsDataPluginModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxsDataPluginModule
});
/** @nocollapse */
NgxsDataPluginModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsDataPluginModule, [{
    type: NgModule
  }], function () {
    return [{
      type: i1.NgxsDataFactory,
      decorators: [{
        type: Self
      }]
    }, {
      type: i1.NgxsDataInjector,
      decorators: [{
        type: Self
      }]
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NGXS_DATA_CONFIG, NgxsDataPluginModule };
