import { isGetter, deepClone, isSimpleObject, deepFreeze } from '@angular-ru/cdk/object';
import * as i0 from '@angular/core';
import { Injectable, Optional, NgZone, Inject, isDevMode } from '@angular/core';
import * as i1 from '@ngxs/store';
import { Store } from '@ngxs/store';
import { BehaviorSubject, ReplaySubject, forkJoin, isObservable } from 'rxjs';
import { isNil, isNotNil, checkValueIsEmpty, isTruthy, isFalsy } from '@angular-ru/cdk/utils';
import { NGXS_DATA_META, NGXS_COMPUTED_OPTION, NGXS_ARGUMENT_REGISTRY_META, NGXS_META_KEY } from '@angular-ru/ngxs/tokens';
import { ɵNGXS_STATE_FACTORY, ɵNGXS_STATE_CONTEXT_FACTORY } from '@ngxs/store/internals';
import { first, map, shareReplay } from 'rxjs/operators';
function validateAction(target, descriptor) {
  const isStaticMethod = target.hasOwnProperty('prototype');
  if (isStaticMethod) {
    throw new Error("Cannot support static methods with @DataAction()" /* NGXS_DATA_STATIC_ACTION */);
  }
  if (descriptor === undefined) {
    throw new Error("@DataAction() can only decorate a method implementation" /* NGXS_DATA_ACTION */);
  }
}
function validateComputedMethod(target, name) {
  const notGetter = !isGetter(target, name?.toString());
  if (notGetter) {
    throw new Error(`${"The method must be a getter for the computed decorator to work properly." /* NGXS_COMPUTED_DECORATOR */}\nExample: \n@Computed() get ${name.toString()}() { \n\t .. \n}`);
  }
}
class NgxsDataSequence {
  constructor(store) {
    this.subscription = null;
    this.sequence$ = new BehaviorSubject(0);
    if (store) {
      this.subscription = store.subscribe(() => this.updateSequence());
    }
  }
  get sequenceValue() {
    return this.sequence$.getValue();
  }
  ngOnDestroy() {
    this.sequence$.next(0);
    this.subscription?.unsubscribe();
  }
  updateSequence() {
    this.sequence$.next(this.sequenceValue + 1);
  }
}
/** @nocollapse */
NgxsDataSequence.ɵfac = function NgxsDataSequence_Factory(t) {
  return new (t || NgxsDataSequence)(i0.ɵɵinject(i1.Store, 8));
};
/** @nocollapse */
NgxsDataSequence.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NgxsDataSequence,
  factory: NgxsDataSequence.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsDataSequence, [{
    type: Injectable
  }], function () {
    return [{
      type: i1.Store,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();
function dynamicActionByType(type) {
  return class NgxsDataAction {
    constructor(payload) {
      if (payload) {
        for (const key of Object.keys(payload)) {
          this[key] = payload[key];
        }
      }
    }
    static get type() {
      return type;
    }
  };
}
function getRepository(target) {
  return target[NGXS_DATA_META];
}
class NgxsDataInjector {
  constructor(injector, stateFactory, stateContextFactory) {
    NgxsDataInjector.store = injector.get(Store);
    NgxsDataInjector.ngZone = injector.get(NgZone);
    NgxsDataInjector.factory = stateFactory;
    NgxsDataInjector.context = stateContextFactory;
    NgxsDataInjector.computed = injector.get(NgxsDataSequence);
    NgxsDataInjector.injector = injector;
  }
}
NgxsDataInjector.store = null;
NgxsDataInjector.computed = null;
NgxsDataInjector.context = null;
NgxsDataInjector.factory = null;
NgxsDataInjector.ngZone = null;
NgxsDataInjector.injector = null;
/** @nocollapse */
NgxsDataInjector.ɵfac = function NgxsDataInjector_Factory(t) {
  return new (t || NgxsDataInjector)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(ɵNGXS_STATE_FACTORY), i0.ɵɵinject(ɵNGXS_STATE_CONTEXT_FACTORY));
};
/** @nocollapse */
NgxsDataInjector.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NgxsDataInjector,
  factory: NgxsDataInjector.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsDataInjector, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.Injector
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [ɵNGXS_STATE_FACTORY]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [ɵNGXS_STATE_CONTEXT_FACTORY]
      }]
    }];
  }, null);
})();
class NgxsDataFactory {
  constructor() {
    NgxsDataFactory.statesCachedMeta.clear();
  }
  static createStateContext(metadata) {
    return NgxsDataInjector.context.createStateContext(metadata);
  }
  static ensureMappedState(stateMeta) {
    if (isNil(NgxsDataInjector.factory) || isNil(stateMeta)) {
      throw new Error("Metadata not created \n Maybe you forgot to import the NgxsDataPluginModule\n Also, you cannot use this.ctx.* until the application is fully rendered \n (use by default ngxsOnInit(ctx: StateContext), or ngxsAfterBootstrap(ctx: StateContext) !!!" /* NGXS_DATA_MODULE_EXCEPTION */);
    }
    const cachedMeta = (isNotNil(stateMeta.name) ? NgxsDataFactory.statesCachedMeta.get(stateMeta.name) : null) || null;
    if (!cachedMeta) {
      return NgxsDataFactory.ensureMeta(stateMeta);
    }
    return cachedMeta;
  }
  static getRepositoryByInstance(target) {
    const stateClass = NgxsDataFactory.getStateClassByInstance(target);
    const repository = getRepository(stateClass) ?? null;
    if (isNil(repository)) {
      throw new Error("You forgot add decorator @StateRepository or initialize state!\nExample: NgxsModule.forRoot([ .. ]), or NgxsModule.forFeature([ .. ])" /* NGXS_DATA_STATE_DECORATOR */);
    }
    return repository;
  }
  static getStateClassByInstance(target) {
    return (target ?? {}).constructor;
  }
  static clearMetaByInstance(target) {
    const repository = NgxsDataFactory.getRepositoryByInstance(target);
    repository.stateMeta.actions = {};
    repository.operations = {};
  }
  static createPayload(args, registry) {
    const payload = {};
    const arrayArgs = Array.from(args);
    for (const [index, arrayArg] of arrayArgs.entries()) {
      const payloadName = registry?.getPayloadTypeByIndex(index);
      if (isNotNil(payloadName)) {
        payload[payloadName] = arrayArg;
      }
    }
    return Object.keys(payload).length > 0 ? payload : null;
  }
  static createAction(operation, args, registry) {
    const payload = NgxsDataFactory.createPayload(args, registry);
    const dynamicActionByTypeFactory = dynamicActionByType(operation.type);
    return new dynamicActionByTypeFactory(payload);
  }
  static ensureMeta(stateMeta) {
    const meta = isNotNil(stateMeta.name) ? NgxsDataInjector.factory.states?.find(state => state.name === stateMeta.name) : null;
    if (isNotNil(meta) && isNotNil(stateMeta.name)) {
      NgxsDataFactory.statesCachedMeta.set(stateMeta.name, meta);
    }
    return meta;
  }
}
NgxsDataFactory.statesCachedMeta = new Map();
/** @nocollapse */
NgxsDataFactory.ɵfac = function NgxsDataFactory_Factory(t) {
  return new (t || NgxsDataFactory)();
};
/** @nocollapse */
NgxsDataFactory.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NgxsDataFactory,
  factory: NgxsDataFactory.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsDataFactory, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
class StorageInitializer {
  constructor() {
    // eslint-disable-next-line rxjs/finnish
    this.subject = new ReplaySubject(1);
  }
  init() {
    this.subject.next();
  }
  onInit(callback) {
    this.subject.pipe(first()).subscribe(callback);
  }
  reset() {
    this.subject.complete();
    this.subject = new ReplaySubject(1);
  }
}
const STORAGE_INITIALIZER = new StorageInitializer();
function actionNameCreator(options) {
  const {
    statePath,
    argumentsNames,
    methodName,
    argumentRegistry
  } = options;
  let argsList = '';
  for (let index = 0; index < argumentsNames.length; index++) {
    if (isNotNil(argumentRegistry?.getArgumentNameByIndex(index))) {
      argsList += argumentRegistry?.getArgumentNameByIndex(index);
    } else if (isNotNil(argumentRegistry?.getPayloadTypeByIndex(index))) {
      argsList += argumentRegistry?.getPayloadTypeByIndex(index);
    } else {
      argsList += `$arg${index}`;
    }
    if (index !== argumentsNames.length - 1) {
      argsList += ', ';
    }
  }
  return `@${statePath.replace(/\./g, '/')}.${methodName}(${argsList})`;
}
class InvalidChildrenException extends Error {
  constructor(currentDefaults) {
    super(`${"Child states can only be added to an object" /* NGXS_DATA_CHILDREN_CONVERT */}. Cannot convert ${isNotNil(currentDefaults?.constructor) ? currentDefaults.constructor.name : currentDefaults} to PlainObject`);
  }
}
function getStoreOptions(stateClass) {
  return stateClass.NGXS_OPTIONS_META ?? {
    name: ''
  };
}
function buildDefaultsGraph(stateClasses) {
  const options = getStoreOptions(stateClasses);
  const children = options.children ?? [];
  const prepared = options.defaults === undefined ? {} : options.defaults;
  const currentDefaults = deepClone(prepared);
  if (children.length > 0) {
    if (isSimpleObject(currentDefaults)) {
      return buildChildrenGraph(currentDefaults, children);
    } else {
      throw new InvalidChildrenException(currentDefaults);
    }
  } else {
    return currentDefaults;
  }
}
function buildChildrenGraph(currentDefaults, children) {
  return children.reduce((defaults, item) => {
    const childrenOptions = getStoreOptions(item);
    if (checkValueIsEmpty(childrenOptions.name)) {
      throw new Error("State name not provided in class" /* NGXS_DATA_STATE_NAME_NOT_FOUND */);
    }
    const name = childrenOptions.name.toString();
    defaults[name] = buildDefaultsGraph(item);
    return defaults;
  }, currentDefaults ?? {});
}
function checkExistNgZone() {
  if (isNil(NgxsDataInjector.ngZone)) {
    throw new Error("Metadata not created \n Maybe you forgot to import the NgxsDataPluginModule\n Also, you cannot use this.ctx.* until the application is fully rendered \n (use by default ngxsOnInit(ctx: StateContext), or ngxsAfterBootstrap(ctx: StateContext) !!!" /* NGXS_DATA_MODULE_EXCEPTION */);
  }
}
function combineStream(dispatched$, result$) {
  return forkJoin([dispatched$, result$]).pipe(map(combines => combines.pop()));
}
function computedKey() {
  return `__${NGXS_COMPUTED_OPTION}__`;
}
function getComputedCache(target) {
  return target[computedKey()] ?? null;
}
function ensureComputedCache(target) {
  const cache = getComputedCache(target);
  if (isNil(cache)) {
    Object.defineProperties(target, {
      [computedKey()]: {
        enumerable: true,
        configurable: true,
        value: new WeakMap()
      }
    });
  }
  return getComputedCache(target);
}
function globalSequenceId() {
  return NgxsDataInjector?.computed?.sequenceValue ?? 0;
}
function itObservable(value) {
  let observable = false;
  if (isObservable(value)) {
    observable = true;
  }
  return observable;
}
function getMethodArgsRegistry(method) {
  return method[NGXS_ARGUMENT_REGISTRY_META];
}
class InvalidArgsNamesException extends Error {
  constructor(name, method) {
    super(`An argument with the name '${name}' already exists in the method '${method}'`);
  }
}
class MethodArgsRegistry {
  constructor() {
    this.payloadMap = new Map();
    this.argumentMap = new Map();
  }
  getPayloadTypeByIndex(index) {
    return this.payloadMap.get(index) ?? null;
  }
  getArgumentNameByIndex(index) {
    return this.argumentMap.get(index) ?? null;
  }
  createPayloadType(name, method, paramIndex) {
    this.checkDuplicateName(name, method);
    this.payloadMap.set(paramIndex, name);
    this.payloadMap.set(name, name);
  }
  createArgumentName(name, method, paramIndex) {
    this.checkDuplicateName(name, method);
    this.argumentMap.set(paramIndex, name);
    this.argumentMap.set(name, name);
  }
  checkDuplicateName(name, method) {
    if (isTruthy(this.argumentMap.has(name)) || isTruthy(this.payloadMap.has(name))) {
      throw new InvalidArgsNamesException(name, method);
    }
  }
}
function ensureMethodArgsRegistry(target, propertyKey) {
  const originMethod = target[propertyKey];
  const registry = getMethodArgsRegistry(originMethod);
  if (isNil(registry)) {
    Object.defineProperties(originMethod, {
      [NGXS_ARGUMENT_REGISTRY_META]: {
        enumerable: true,
        configurable: true,
        value: new MethodArgsRegistry()
      }
    });
  }
  return getMethodArgsRegistry(originMethod);
}
function defineDefaultRepositoryMeta(target) {
  Object.defineProperty(target, NGXS_DATA_META, {
    writable: true,
    configurable: true,
    enumerable: true,
    value: {
      stateMeta: null,
      operations: {},
      stateClass: target
    }
  });
}

/**
 * @description
 * don't use !target.hasOwnProperty(NGXS_DATA_META),
 * because you need support access from parent inheritance class
 */
function ensureRepository(target) {
  const repository = getRepository(target) ?? null;
  const metaNotFound = isNil(repository) || repository?.stateClass !== target;
  if (metaNotFound) {
    defineDefaultRepositoryMeta(target);
  }
  return getRepository(target);
}

/**
 * @description need mutate metadata for correct reference
 */
function createRepositoryMetadata(target, stateMeta) {
  const repositoryMeta = ensureRepository(target);
  repositoryMeta.stateMeta = stateMeta;
}
function ensureSnapshot(state) {
  return isDevMode() ? deepFreeze(state) : state;
}
function createContext(stateClass) {
  return {
    enumerable: true,
    configurable: true,
    get() {
      const meta = getRepository(stateClass);
      const mappedMeta = NgxsDataFactory.ensureMappedState(meta.stateMeta);
      return NgxsDataFactory.createStateContext(mappedMeta);
    }
  };
}

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
function createStateSelector(stateClass) {
  const repository = getRepository(stateClass);
  const name = repository?.stateMeta?.name ?? null;
  if (isNotNil(name)) {
    const selectorId = `__${name}__selector`;
    Object.defineProperties(stateClass.prototype, {
      [selectorId]: {
        writable: true,
        enumerable: false,
        configurable: true
      },
      state$: {
        enumerable: true,
        configurable: true,
        get() {
          if (isNotNil(this[selectorId])) {
            return this[selectorId];
          } else {
            if (isNil(NgxsDataInjector.store)) {
              throw new Error("Metadata not created \n Maybe you forgot to import the NgxsDataPluginModule\n Also, you cannot use this.ctx.* until the application is fully rendered \n (use by default ngxsOnInit(ctx: StateContext), or ngxsAfterBootstrap(ctx: StateContext) !!!" /* NGXS_DATA_MODULE_EXCEPTION */);
            }
            this[selectorId] = NgxsDataInjector.store.select(stateClass).pipe(map(state => isDevMode() ? deepFreeze(state) : state), shareReplay({
              refCount: true,
              bufferSize: 1
            }));
          }
          return this[selectorId];
        }
      }
    });
  }
}
function ensureDataStateContext(context) {
  if (!context) {
    throw new Error("You forgot add decorator @StateRepository or initialize state!\nExample: NgxsModule.forRoot([ .. ]), or NgxsModule.forFeature([ .. ])" /* NGXS_DATA_STATE_DECORATOR */);
  }
  return {
    ...context,
    getState() {
      return isDevMode() ? deepFreeze(context.getState()) : context.getState();
    },
    setState(value) {
      context.setState(value);
    },
    patchState(value) {
      context.patchState(value);
    }
  };
}
function getStateMetadata(target) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  return target?.[NGXS_META_KEY];
}
function ensureStateMetadata(target) {
  if (isFalsy(target.hasOwnProperty(NGXS_META_KEY))) {
    const defaultMetadata = {
      name: null,
      actions: {},
      defaults: {},
      path: null,
      makeRootSelector(context) {
        return context.getStateGetter(defaultMetadata.name);
      },
      children: []
    };
    Object.defineProperty(target, NGXS_META_KEY, {
      value: defaultMetadata
    });
  }
  return getStateMetadata(target);
}

/**
 * Generated bundle index. Do not edit.
 */

export { MethodArgsRegistry, NgxsDataFactory, NgxsDataInjector, NgxsDataSequence, STORAGE_INITIALIZER, actionNameCreator, buildDefaultsGraph, checkExistNgZone, combineStream, createContext, createRepositoryMetadata, createStateSelector, defineDefaultRepositoryMeta, dynamicActionByType, ensureComputedCache, ensureDataStateContext, ensureMethodArgsRegistry, ensureRepository, ensureSnapshot, ensureStateMetadata, getComputedCache, getMethodArgsRegistry, getRepository, getStateMetadata, getStoreOptions, globalSequenceId, itObservable, validateAction, validateComputedMethod };
