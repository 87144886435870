import { TagsGameInterface } from '@state/games/tags/tags.interfaces';
import {
  NewBingoInformationInterface,
  QuestionsBingoInterface,
} from '../games/add-bingo/add-bingo.interfaces';
import { GameplayStatusEnum } from '../games/play/play.interfaces';
import { NameIdInterface } from '@shared/interfaces/name-id.interface';
import { Paginate } from '@shared/interfaces/paginate.interface';

export enum Role {
  learner = 0,
  teacher = 1,
  admin = 2,
  superAdmin = 10,
}

export interface UserInterface extends NameIdInterface {
  phone: string;
  email: string;
  role: Role;
  agree: boolean | undefined;
  country: NameIdInterface[] | null | undefined;
}

export interface TeacherInterface extends UserInterface {
  created_games: number;
  played_games_count: number;
  total_players: number;
  minimal_play_date: Date;
  maximal_play_date: Date;
}

export interface UserStateInterface {
  user: UserInterface;
  userCountries: CountryInterface[];
  myGames: MyGamesInterfacePaginate;
  myFilteredGames: MyGamesInterfacePaginate;
  publicGames: MyGamesInterfacePaginate;
  publicFilteredGames: MyGamesInterfacePaginate;
  gamesSearch: string;
  gameFilter: GameFilterInterface;
  isChangedGamesFilter: boolean;
  isChangedUserPersonalData: boolean;
  gamesCounts: MyGamesCountsInterface;
  isDesktop: boolean;
}

export interface GameFilterInterface {
  // isEmpty: boolean;
  language: number[];
  age: number[];
  category: number[];
}

export interface UserChangeNumberResponse {
  code_id: number;
  code: number;
}
export interface UserChangeNumber extends Partial<UserChangeNumberResponse> {
  new_phone: string;
}

export interface CountryInterface extends NameIdInterface {}

export interface CountryPaginateInterface extends Paginate {
  data: CountryInterface[];
}

export interface CountriesUpdateInterface {
  countries: number[];
}

export interface MyGamesInterface extends NewBingoInformationInterface {
  questions?: QuestionsBingoInterface[];
  status?: GameplayStatusEnum;

  tags?: TagsGameInterface;
  played_times?: number;
  created_at?: string;
}

export interface MyGames2ColumnsInterface {
  row: MyGamesInterface[];
}

export interface MyGamesInterfacePaginate extends Paginate {
  games: MyGamesInterface[];
  data?: MyGamesInterface[];
}

export interface MyGamesCountsInterface {
  my_games_count: number;
  my_games_max_count: number;
  my_public_games_count: number;
  my_public_games_max_count: number;
  max_students_per_game_count: number;
}
