import { isTrue, isNil, isNotNil } from '@angular-ru/cdk/utils';
import { validateComputedMethod, ensureComputedCache, globalSequenceId, itObservable, validateAction, NgxsDataFactory, getMethodArgsRegistry, actionNameCreator, NgxsDataInjector, combineStream, checkExistNgZone, ensureMethodArgsRegistry, ensureStateMetadata, getRepository, STORAGE_INITIALIZER, createRepositoryMetadata, buildDefaultsGraph, createStateSelector, createContext } from '@angular-ru/ngxs/internals';
import { InjectFlags, isDevMode } from '@angular/core';
import { $args } from '@angular-ru/cdk/function';
import { NGXS_DATA_CONFIG } from '@angular-ru/ngxs';
import { isObservable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ensureProviders, registerStorageProviders } from '@angular-ru/ngxs/storage';
import { deepClone } from '@angular-ru/cdk/object';

// eslint-disable-next-line max-lines-per-function
function Computed() {
    // eslint-disable-next-line max-lines-per-function
    return (target, key, descriptor) => {
        validateComputedMethod(target, key);
        const originalMethod = descriptor.get;
        descriptor.get = function (...args) {
            const cacheMap = ensureComputedCache(this);
            const cache = cacheMap?.get(originalMethod);
            if (isTrue(cache?.isObservable)) {
                return cache?.value;
            }
            const invalidCache = isNil(cache) || cache.sequenceId !== globalSequenceId();
            if (invalidCache) {
                cacheMap.delete(originalMethod);
                const value = originalMethod.apply(this, args);
                cacheMap.set(originalMethod, {
                    value,
                    sequenceId: globalSequenceId(),
                    isObservable: itObservable(value)
                });
                return value;
            }
            else {
                return cache?.value;
            }
        };
        return descriptor;
    };
}

const REPOSITORY_ACTION_OPTIONS = {
    cancelUncompleted: true,
    insideZone: false,
    subscribeRequired: true
};

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
function DataAction(options = {}) {
    const config = mergeConfig(options);
    // eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
    return (target, name, descriptor) => {
        validateAction(target, descriptor);
        const originalMethod = descriptor.value;
        const key = name.toString();
        // eslint-disable-next-line max-lines-per-function
        descriptor.value = function (...args) {
            const instance = this;
            let result = null;
            const repository = NgxsDataFactory.getRepositoryByInstance(instance);
            const operations = repository.operations;
            let operation = operations[key];
            const stateMeta = repository.stateMeta;
            const registry = getMethodArgsRegistry(originalMethod);
            if (isNil(operation)) {
                // Note: late init operation when first invoke action method
                const argumentsNames = $args(originalMethod);
                const type = actionNameCreator({
                    statePath: stateMeta.path,
                    methodName: key,
                    argumentsNames,
                    argumentRegistry: registry
                });
                operation = operations[key] = {
                    type,
                    options: { cancelUncompleted: config.cancelUncompleted ?? false }
                };
                if (isNotNil(operation)) {
                    stateMeta.actions[operation.type] = [
                        { type: operation.type, options: operation.options, fn: operation.type }
                    ];
                }
            }
            const mapped = NgxsDataFactory.ensureMappedState(stateMeta);
            const stateInstance = mapped.instance;
            // Note: invoke only after store.dispatch(...)
            stateInstance[operation.type] = () => {
                if (isTrue(config.insideZone)) {
                    NgxsDataInjector.ngZone?.run(() => {
                        result = originalMethod.apply(instance, args);
                    });
                }
                else {
                    result = originalMethod.apply(instance, args);
                }
                // Note: store.dispatch automatically subscribes, but we don't need it
                // We want to subscribe ourselves manually, but this behavior can be changed by config
                return isObservable(result) && isTrue(config.subscribeRequired)
                    ? of(null).pipe(map(() => result))
                    : result;
            };
            const event = NgxsDataFactory.createAction(operation, args, registry);
            const dispatcher$ = NgxsDataInjector.store.dispatch(event);
            if (isObservable(result)) {
                return combineStream(dispatcher$, result);
            }
            else {
                return result;
            }
        };
        return descriptor;
    };
}
function mergeConfig(options) {
    const globalConfig = NgxsDataInjector?.injector?.get(NGXS_DATA_CONFIG, undefined, InjectFlags.Optional);
    const mergedOptions = { ...REPOSITORY_ACTION_OPTIONS };
    if (isNotNil(globalConfig) && globalConfig?.dataActionSubscribeRequired !== undefined) {
        mergedOptions.subscribeRequired = globalConfig.dataActionSubscribeRequired;
    }
    return { ...mergedOptions, ...options };
}

const DEFAULT_TIMEOUT = 300;
function Debounce(timeout = DEFAULT_TIMEOUT) {
    let timeoutRef = null;
    return (_target, _name, descriptor) => {
        const originalMethod = descriptor.value;
        descriptor.value = function (...args) {
            checkExistNgZone();
            NgxsDataInjector.ngZone?.runOutsideAngular(() => {
                window.clearTimeout(timeoutRef);
                // eslint-disable-next-line no-restricted-properties
                timeoutRef = window.setTimeout(() => {
                    const result = originalMethod.apply(this, args);
                    if (isDevMode() && isNotNil(result)) {
                        console.warn("WARNING: If you use asynchronous actions `@Debounce() @DataAction()` the return result type should only void instead:" /* NGXS_DATA_ASYNC_ACTION_RETURN_TYPE */, result);
                    }
                }, timeout);
            });
        };
        return descriptor;
    };
}

function Named(name) {
    return (stateClass, methodName, parameterIndex) => {
        const key = name.trim();
        if (!key) {
            throw new Error("Argument name should be initialized" /* NGXS_INVALID_ARG_NAME */);
        }
        const registry = ensureMethodArgsRegistry(stateClass, methodName);
        registry.createArgumentName(key, methodName, parameterIndex);
    };
}

function Payload(name) {
    return (stateClass, methodName, parameterIndex) => {
        const key = name.trim();
        if (!key) {
            throw new Error("Payload name should be initialized" /* NGXS_INVALID_PAYLOAD_NAME */);
        }
        const registry = ensureMethodArgsRegistry(stateClass, methodName);
        registry.createPayloadType(key, methodName, parameterIndex);
    };
}

function Persistence(options) {
    return (stateClass) => {
        const stateMeta = ensureStateMetadata(stateClass);
        const repositoryMeta = getRepository(stateClass);
        const isUndecoratedClass = isNil(stateMeta.name) || isNil(repositoryMeta);
        if (isUndecoratedClass) {
            throw new Error("@Persistence should be add before decorator @State and @StateRepository" /* NGXS_PERSISTENCE_STATE */);
        }
        STORAGE_INITIALIZER.onInit(() => {
            const providers = ensureProviders(repositoryMeta, stateClass, options);
            registerStorageProviders(providers);
        });
    };
}

function StateRepository() {
    return (stateClass) => {
        const stateMeta = ensureStateMetadata(stateClass);
        if (isNil(stateMeta.name)) {
            throw new Error("@StateRepository should be add before decorator @State" /* NGXS_DATA_STATE */);
        }
        createRepositoryMetadata(stateClass, stateMeta);
        const cloneDefaults = buildDefaultsGraph(stateClass);
        defineProperties(stateClass, stateMeta, cloneDefaults);
        createStateSelector(stateClass);
    };
}
function defineProperties(stateClass, stateMeta, cloneDefaults) {
    Object.defineProperties(stateClass.prototype, {
        name: {
            enumerable: true,
            configurable: true,
            value: stateMeta.name
        },
        initialState: {
            enumerable: true,
            configurable: true,
            get() {
                // preserve mutation
                return deepClone(cloneDefaults);
            }
        },
        context: createContext(stateClass)
    });
}

/**
 * Generated bundle index. Do not edit.
 */

export { Computed, DataAction, Debounce, Named, Payload, Persistence, StateRepository };

