import * as i0 from '@angular/core';
import { InjectionToken, inject, PLATFORM_ID, Injectable, Inject, Self } from '@angular/core';
import { actionMatcher, InitState, UpdateState, getValue, setValue, Store, NGXS_PLUGINS } from '@ngxs/store';
import { isPlatformServer } from '@angular/common';
import { isSimpleObject, isGetter } from '@angular-ru/cdk/object';
import { isNil, isNotNil, isFalsy, isTruthy, checkValueIsEmpty, checkValueIsFilled } from '@angular-ru/cdk/utils';
import { getStateMetadata, NgxsDataInjector, STORAGE_INITIALIZER } from '@angular-ru/ngxs/internals';
import { NGXS_DATA_STORAGE_EVENT_TYPE } from '@angular-ru/ngxs/tokens';
import { interval, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
const NGXS_DATA_STORAGE_CONTAINER_TOKEN = new InjectionToken('NGXS_DATA_STORAGE_CONTAINER_TOKEN');
class NgxsDataStorageContainer {
  constructor() {
    this.providers = new Set();
    this.keys = new Map();
  }
  getProvidedKeys() {
    return Array.from(this.keys.keys());
  }
}
function storageUseFactory() {
  return new NgxsDataStorageContainer();
}
const NGXS_DATA_STORAGE_CONTAINER = {
  provide: NGXS_DATA_STORAGE_CONTAINER_TOKEN,
  useFactory: storageUseFactory
};
const NGXS_DATA_STORAGE_DECODE_TYPE_TOKEN = new InjectionToken('NGXS_DATA_STORAGE_DECODE_TYPE_TOKEN');
const NGXS_DATA_STORAGE_DECODE_TYPE = {
  provide: NGXS_DATA_STORAGE_DECODE_TYPE_TOKEN,
  useValue: "none" /* NONE */
};
function existTtl(provider) {
  return provider.ttl !== -1 && !isNaN(provider.ttl) && provider.ttl > 0;
}
function isExpiredByTtl(expiry) {
  return isNil(expiry) ? true : Date.now() >= expiry.getTime();
}
function canBePullFromStorage(options) {
  const {
    data,
    provider
  } = options;
  const canBeOverrideFromStorage = isNotNil(data) || provider.nullable;
  let result = {
    canBeOverrideFromStorage,
    versionMismatch: false,
    expired: false,
    expiry: null
  };
  result = ensureInfoByTtl(canBeOverrideFromStorage, result, options);
  result = ensureInfoByVersionMismatch(canBeOverrideFromStorage, result, options);
  return result;
}
function ensureInfoByTtl(canBeOverrideFromStorage, result, options) {
  let newResult = result;
  const {
    meta,
    provider
  } = options;
  if (canBeOverrideFromStorage && existTtl(provider)) {
    const expiry = new Date(meta.expiry);
    const expiryExist = !isNaN(expiry.getTime());
    if (expiryExist) {
      if (isExpiredByTtl(expiry)) {
        newResult = {
          canBeOverrideFromStorage: false,
          expired: true,
          expiry,
          versionMismatch: false
        };
      } else {
        newResult = {
          canBeOverrideFromStorage,
          expired: false,
          expiry,
          versionMismatch: false
        };
      }
    }
  }
  return newResult;
}
function ensureInfoByVersionMismatch(canBeOverrideFromStorage, result, options) {
  let newResult = result;
  const {
    meta,
    provider
  } = options;
  if (canBeOverrideFromStorage && meta.version !== provider.version) {
    const instance = provider.stateInstance;
    const tryMigrate =
    // eslint-disable-next-line @typescript-eslint/unbound-method
    isFalsy(provider.skipMigrate) && (isTruthy(instance?.ngxsDataStorageMigrate) || isTruthy(provider.migrate));
    if (tryMigrate) {
      newResult = {
        ...result,
        versionMismatch: true
      };
    } else {
      newResult = {
        ...result,
        canBeOverrideFromStorage: false,
        versionMismatch: true
      };
    }
  }
  return newResult;
}
function ensurePath(provider) {
  return provider.path ?? getStateMetadata(provider.stateClassRef).path;
}
function ensureKey(provider) {
  return `${provider.prefixKey}${ensurePath(provider)}`;
}
function firedStateWhenExpired(key, options) {
  const {
    provider,
    expiry
  } = options;
  const event = {
    key,
    expiry: expiry?.toISOString(),
    timestamp: new Date(Date.now()).toISOString()
  };
  const instance = provider.stateInstance;
  instance?.expired$?.next(event);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  if (isNotNil(instance?.ngxsDataAfterExpired)) {
    if (isNotNil(NgxsDataInjector.ngZone)) {
      NgxsDataInjector.ngZone?.run(() => instance?.ngxsDataAfterExpired?.(event, provider));
    } else {
      instance?.ngxsDataAfterExpired?.(event, provider);
    }
  }
}
const SPACE = 4;
class InvalidStructureDataException extends Error {
  constructor(message) {
    super(`${message}. \nIncorrect structure for deserialization!!! Your structure should be like this: \n${JSON.stringify({
      lastChanged: '2020-01-01T12:00:00.000Z',
      data: '{}',
      version: 1
    }, null, SPACE)}`);
  }
}
function parseStorageMeta(value) {
  try {
    return JSON.parse(value);
  } catch (error) {
    throw new InvalidStructureDataException(error.message);
  }
}
function ttlStrategyHandler(key, value, options) {
  const {
    provider,
    engine
  } = options;
  const meta = parseStorageMeta(value);
  switch (provider.ttlExpiredStrategy) {
    case 0 /* REMOVE_KEY_AFTER_EXPIRED */:
      engine.removeItem(key);
      break;
    case 1 /* SET_NULL_DATA_AFTER_EXPIRED */:
      meta.data = null;
      engine.setItem(key, JSON.stringify(meta));
      break;
    case 2 /* DO_NOTHING_AFTER_EXPIRED */:
    default:
      break;
  }
}
function ttlHandler(start, options, subscription) {
  const {
    provider,
    expiry,
    map,
    engine
  } = options;
  const key = ensureKey(provider);
  const value = engine.getItem(key);
  if (isNotNil(value)) {
    if (isExpiredByTtl(expiry)) {
      const endListen = new Date(Date.now()).toISOString();
      ttlStrategyHandler(key, value, options);
      firedStateWhenExpired(key, options);
      subscription.unsubscribe();
      map.set(provider, {
        subscription,
        startListen: start,
        endListen
      });
    }
  } else {
    subscription.unsubscribe();
  }
}
function createTtlInterval(options) {
  const {
    provider,
    map
  } = options;
  map.get(provider)?.subscription.unsubscribe();
  const watcher = () => {
    const startListen = new Date(Date.now()).toISOString();
    const subscription = interval(provider.ttlDelay).subscribe(() => ttlHandler(startListen, options, subscription));
    map.set(provider, {
      subscription,
      startListen,
      endListen: null
    });
  };
  if (isNotNil(NgxsDataInjector.ngZone)) {
    NgxsDataInjector.ngZone?.runOutsideAngular(() => watcher());
  } else {
    watcher();
  }
}
class InvalidDataValueException extends Error {
  constructor() {
    super(`missing key 'data' or it's value not serializable.`);
  }
}
class InvalidLastChangedException extends Error {
  constructor(value) {
    super(`lastChanged key not found in object ${value}.`);
  }
}
class InvalidVersionException extends Error {
  constructor(value) {
    super(`It's not possible to determine version (${value}), since it must be a integer type and must equal or more than 1.`);
  }
}
function deserializeByStorageMeta(meta, value, provider) {
  if (isSimpleObject(meta)) {
    if (missingLastChanged(meta)) {
      throw new InvalidLastChangedException(value);
    } else if (versionIsInvalid(meta)) {
      throw new InvalidVersionException(meta.version);
    } else if (missingDataKey(meta)) {
      throw new InvalidDataValueException();
    }
    return provider.decode === "base64" /* BASE64 */ ? JSON.parse(window.atob(meta.data)) : meta.data;
  } else {
    throw new InvalidStructureDataException(`"${value}" not an object`);
  }
}
function versionIsInvalid(meta) {
  const version = parseFloat(meta.version?.toString() ?? '');
  return isNaN(version) || version < 1 || parseInt(meta.version?.toString()) !== version;
}
function missingDataKey(meta) {
  return !('data' in meta);
}
function missingLastChanged(meta) {
  return !('lastChanged' in meta) || checkValueIsEmpty(meta.lastChanged);
}
function ensureSerializeData(data, provider) {
  const dataLocal = isNotNil(data) ? data : null;
  return provider.decode === "base64" /* BASE64 */ ? window.btoa(JSON.stringify(dataLocal)) : dataLocal;
}
class NotDeclareEngineException extends Error {
  constructor(key) {
    super(`${"Not found storage engine from `existingEngine` or not found instance after injecting by `useClass`." /* NGXS_PERSISTENCE_ENGINE */} \nMetadata { key: '${key}' }`);
  }
}
class NotImplementedStorageException extends Error {
  constructor() {
    super(`StorageEngine instance should be implemented by DataStorageEngine interface`);
  }
}
function exposeEngine(provider, injector) {
  const engine = provider?.existingEngine ?? injector.get(provider?.useClass, null);
  if (isNil(engine)) {
    throw new NotDeclareEngineException(ensureKey(provider));
  } else if (!('getItem' in engine)) {
    throw new NotImplementedStorageException();
  }
  return engine;
}
function isInitAction(action) {
  const matches = actionMatcher(action);
  return matches(InitState) || matches(UpdateState);
}
function isStorageEvent(action) {
  return action.type === NGXS_DATA_STORAGE_EVENT_TYPE;
}

// eslint-disable-next-line max-lines-per-function
function rehydrate(params) {
  let states = params.states;
  const {
    provider,
    data,
    info
  } = params;
  if (isFalsy(provider.rehydrate)) {
    return {
      states,
      rehydrateIn: false
    };
  }
  const path = ensurePath(provider);
  const prevData = getValue(states, path);
  if (isTruthy(info.versionMismatch)) {
    const stateInstance = provider.stateInstance;
    const instance = stateInstance;
    const migrateFn = provider.migrate ?? instance.ngxsDataStorageMigrate?.bind(provider.stateInstance);
    const newMigrationData = migrateFn?.(prevData, data);
    states = setValue(states, path, newMigrationData);
    return {
      states,
      rehydrateIn: true
    };
  } else if (JSON.stringify(prevData) !== JSON.stringify(data)) {
    states = setValue(states, path, data);
    return {
      states,
      rehydrateIn: true
    };
  }
  return {
    states,
    rehydrateIn: false
  };
}
function silentDeserializeWarning(key, value, error) {
  console.warn(`${"Error occurred while deserializing value" /* NGXS_PERSISTENCE_DESERIALIZE */} from metadata { key: '${key}', value: '${value}' }. \nError deserialize: ${error}`);
}
function silentSerializeWarning(key, error) {
  console.warn(`${"Error occurred while serializing value" /* NGXS_PERSISTENCE_SERIALIZE */} from metadata { key: '${key}' }. \nError serialize: ${error}`);
}
class NgxsDataStoragePlugin {
  constructor(platformId, injector) {
    this.platformId = platformId;
    NgxsDataStoragePlugin.injector = injector;
    STORAGE_INITIALIZER.init();
    this.listenWindowEvents();
  }
  get store() {
    return NgxsDataStoragePlugin.injector.get(Store, null);
  }
  get size() {
    return this.providers.size;
  }
  get ttlListeners() {
    return NgxsDataStoragePlugin.ttlListeners;
  }
  /**
   * @description:
   * The storage container that contains meta information about
   */
  get container() {
    return NgxsDataStoragePlugin.injector.get(NGXS_DATA_STORAGE_CONTAINER_TOKEN);
  }
  /**
   * @description:
   * Meta information about all the added keys and their options
   */
  get providers() {
    return this.container.providers;
  }
  /**
   * @description:
   * Keys needed for dynamic synchronization with StorageEvents from
   * localStorage or sessionStorage
   */
  get keys() {
    return this.container.keys;
  }
  get entries() {
    return this.providers.entries();
  }
  get skipStorageInterceptions() {
    return this.size === 0 || isPlatformServer(this.platformId);
  }
  static checkIsStorageEvent(options, info, data) {
    const {
      action,
      provider,
      key,
      value
    } = options;
    if (isTruthy(info.rehydrateIn) && isTruthy(isStorageEvent(action))) {
      const instance = provider.stateInstance;
      const event = {
        key,
        value,
        data,
        provider
      };
      instance?.browserStorageEvents$.next(event);
      // eslint-disable-next-line @typescript-eslint/unbound-method
      if (isTruthy(instance?.ngxsDataAfterStorageEvent)) {
        instance?.ngxsDataAfterStorageEvent?.(event);
      }
    }
  }
  static mutateProviderWithInjectStateInstance(provider) {
    if (isFalsy(provider.stateInstance)) {
      try {
        provider.stateInstance = NgxsDataStoragePlugin.injector?.get(provider.stateClassRef, null) ?? inject(provider.stateClassRef);
      } catch {}
    }
  }
  static checkExpiredInit(params) {
    const {
      info,
      rehydrateInfo,
      options,
      map
    } = params;
    const {
      provider,
      engine
    } = options;
    if (isTruthy(rehydrateInfo.rehydrateIn) && isTruthy(info.expiry)) {
      createTtlInterval({
        provider,
        expiry: info.expiry,
        map,
        engine
      });
    }
  }
  static canBeSyncStoreWithStorage(action, init) {
    return init || action.type === NGXS_DATA_STORAGE_EVENT_TYPE;
  }
  handle(states, action, next) {
    if (this.skipStorageInterceptions) {
      return next(states, action);
    }
    const init = isInitAction(action);
    for (const [provider] of this.entries) {
      NgxsDataStoragePlugin.mutateProviderWithInjectStateInstance(provider);
    }
    const newStates = this.pullStateFromStorage(states, {
      action,
      init
    });
    return next(newStates, action).pipe(tap(nextState => this.pushStateToStorage(newStates, nextState, {
      action,
      init
    })));
  }
  serialize(data, provider) {
    const meta = {
      version: provider.version,
      lastChanged: new Date().toISOString(),
      data: ensureSerializeData(data, provider)
    };
    if (isTruthy(existTtl(provider))) {
      const engine = exposeEngine(provider, NgxsDataStoragePlugin.injector);
      const expiry = new Date(Date.now() + parseInt(provider.ttl));
      createTtlInterval({
        provider,
        expiry,
        map: this.ttlListeners,
        engine
      });
      meta.expiry = expiry.toISOString();
    }
    return JSON.stringify(meta);
  }
  deserialize(meta, value, provider) {
    return deserializeByStorageMeta(meta, value, provider);
  }
  pushStateToStorage(states, nextState, meta) {
    for (const [provider] of this.entries) {
      const prevData = getValue(states, ensurePath(provider));
      const newData = getValue(nextState, ensurePath(provider));
      const canBeInitFire = isTruthy(provider.fireInit) && meta.init;
      if (prevData !== newData || canBeInitFire) {
        const engine = exposeEngine(provider, NgxsDataStoragePlugin.injector);
        const key = ensureKey(provider);
        try {
          const data = this.serialize(newData, provider);
          engine.setItem(key, data);
          this.keys.set(key);
        } catch (error) {
          silentSerializeWarning(key, error.message);
        }
      }
    }
  }
  pullStateFromStorage(states, {
    action,
    init
  }) {
    let newStates = states;
    if (NgxsDataStoragePlugin.canBeSyncStoreWithStorage(action, init)) {
      for (const [provider] of this.entries) {
        newStates = this.deserializeByProvider(newStates, action, provider);
      }
    }
    return newStates;
  }
  deserializeByProvider(states, action, provider) {
    let newState = states;
    const key = ensureKey(provider);
    if (!isGetter(provider, 'path')) {
      provider.path = ensurePath(provider);
    }
    const engine = exposeEngine(provider, NgxsDataStoragePlugin.injector);
    const value = engine.getItem(key);
    const existValueByKeyInStorage = isNotNil(value);
    if (existValueByKeyInStorage) {
      newState = this.deserializeHandler(newState, {
        key,
        engine,
        provider,
        value,
        action
      });
    }
    return newState;
  }
  deserializeHandler(states, options) {
    const {
      key,
      provider,
      value
    } = options;
    try {
      const meta = parseStorageMeta(value);
      const data = this.deserialize(meta, value, provider);
      const info = canBePullFromStorage({
        provider,
        meta,
        data
      });
      if (isTruthy(info.canBeOverrideFromStorage)) {
        const rehydrateInfo = rehydrate({
          states,
          provider,
          data,
          info
        });
        this.keys.set(key);
        // mutate parent states
        // eslint-disable-next-line no-param-reassign
        states = rehydrateInfo.states;
        NgxsDataStoragePlugin.checkIsStorageEvent(options, rehydrateInfo, data);
        NgxsDataStoragePlugin.checkExpiredInit({
          info,
          rehydrateInfo,
          options,
          map: this.ttlListeners
        });
      } else {
        this.removeKeyWhenPullInvalid(info, options);
      }
    } catch (error) {
      silentDeserializeWarning(key, value, error.message);
    }
    return states;
  }
  removeKeyWhenPullInvalid(info, options) {
    const {
      key,
      engine,
      provider
    } = options;
    if (isTruthy(info.expired)) {
      firedStateWhenExpired(key, {
        provider,
        engine,
        map: this.ttlListeners,
        expiry: info.expiry
      });
    }
    engine.removeItem(key);
    this.keys.delete(key);
  }
  listenWindowEvents() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    NgxsDataStoragePlugin.eventsSubscriptions?.unsubscribe();
    NgxsDataStoragePlugin.eventsSubscriptions = fromEvent(window, 'storage').subscribe(event => {
      const keyUsageInStore = checkValueIsFilled(event.key) && this.keys.has(event.key);
      if (keyUsageInStore) {
        // eslint-disable-next-line rxjs/no-nested-subscribe,rxjs/no-ignored-subscribe
        this.store.dispatch({
          type: NGXS_DATA_STORAGE_EVENT_TYPE
        }).subscribe();
      }
    });
  }
}
NgxsDataStoragePlugin.injector = null;
NgxsDataStoragePlugin.eventsSubscriptions = null;
NgxsDataStoragePlugin.ttlListeners = new WeakMap();
/** @nocollapse */
NgxsDataStoragePlugin.ɵfac = function NgxsDataStoragePlugin_Factory(t) {
  return new (t || NgxsDataStoragePlugin)(i0.ɵɵinject(PLATFORM_ID), i0.ɵɵinject(i0.Injector, 2));
};
/** @nocollapse */
NgxsDataStoragePlugin.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NgxsDataStoragePlugin,
  factory: NgxsDataStoragePlugin.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsDataStoragePlugin, [{
    type: Injectable
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: i0.Injector,
      decorators: [{
        type: Self
      }]
    }];
  }, null);
})();
const NGXS_DATA_STORAGE_EXTENSION = {
  provide: NGXS_PLUGINS,
  useClass: NgxsDataStoragePlugin,
  multi: true
};
const NGXS_DATA_STORAGE_PREFIX_TOKEN = new InjectionToken('NGXS_DATA_STORAGE_PREFIX_TOKEN');
const DEFAULT_KEY_PREFIX = '@ngxs.store.';
const NGXS_DATA_STORAGE_PREFIX = {
  provide: NGXS_DATA_STORAGE_PREFIX_TOKEN,
  useValue: DEFAULT_KEY_PREFIX
};

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const STORAGE_TTL_DELAY = 1000 * 60; // 1min

// eslint-disable-next-line max-lines-per-function
function createDefault(options) {
  const {
    meta,
    decodeType,
    prefix,
    stateClassRef
  } = options;
  return [{
    get path() {
      return meta?.stateMeta?.path;
    },
    existingEngine: localStorage,
    ttl: -1,
    version: 1,
    decode: decodeType,
    prefixKey: prefix,
    nullable: false,
    fireInit: true,
    rehydrate: true,
    ttlDelay: STORAGE_TTL_DELAY,
    ttlExpiredStrategy: 0 /* REMOVE_KEY_AFTER_EXPIRED */,
    stateClassRef,
    skipMigrate: false
  }];
}
function validatePathInProvider(meta, provider) {
  let newProvider = provider;
  if (!('path' in newProvider)) {
    newProvider = {
      ...newProvider,
      get path() {
        return meta?.stateMeta?.path;
      }
    };
  }
  return newProvider;
}

// eslint-disable-next-line complexity
function mergeOptions({
  option,
  decodeType,
  prefix,
  meta,
  stateClassRef
}) {
  const provider = Object.assign(option, {
    ttl: isNotNil(option.ttl) ? option.ttl : -1,
    version: isNotNil(option.version) ? option.version : 1,
    decode: isNotNil(option.decode) ? option.decode : decodeType,
    prefixKey: isNotNil(option.prefixKey) ? option.prefixKey : prefix,
    nullable: isNotNil(option.nullable) ? option.nullable : false,
    fireInit: isNotNil(option.fireInit) ? option.fireInit : true,
    rehydrate: isNotNil(option.rehydrate) ? option.rehydrate : true,
    ttlDelay: isNotNil(option.ttlDelay) ? option.ttlDelay : STORAGE_TTL_DELAY,
    ttlExpiredStrategy: isNotNil(option.ttlExpiredStrategy) ? option.ttlExpiredStrategy : 0 /* REMOVE_KEY_AFTER_EXPIRED */,
    stateClassRef: isNotNil(option.stateClassRef) ? option.stateClassRef : stateClassRef,
    skipMigrate: isNotNil(option.skipMigrate) ? option.skipMigrate : false
  });
  return validatePathInProvider(meta, provider);
}

// eslint-disable-next-line max-lines-per-function
function ensureProviders(meta, stateClassRef, options) {
  let providers;
  const prefix = NgxsDataStoragePlugin.injector?.get(NGXS_DATA_STORAGE_PREFIX_TOKEN, DEFAULT_KEY_PREFIX) ?? DEFAULT_KEY_PREFIX;
  const decodeType = NgxsDataStoragePlugin.injector?.get(NGXS_DATA_STORAGE_DECODE_TYPE_TOKEN, "none" /* NONE */) ?? "none" /* NONE */;
  if (isNotNil(options)) {
    const prepared = Array.isArray(options) ? options : [options];
    providers = prepared.map(option => mergeOptions({
      option,
      prefix,
      decodeType,
      meta,
      stateClassRef
    }));
  } else {
    providers = createDefault({
      meta,
      prefix,
      decodeType,
      stateClassRef
    });
  }
  return providers;
}
function registerStorageProviders(options) {
  try {
    const container = NgxsDataStoragePlugin.injector?.get(NGXS_DATA_STORAGE_CONTAINER_TOKEN);
    for (const option of options) {
      container?.providers.add(option);
    }
  } catch {
    throw new Error("You forgot provide NGXS_DATA_STORAGE_CONTAINER or NGXS_DATA_STORAGE_EXTENSION!!! Example: \n\n@NgModule({\n imports: [ \n   NgxsDataPluginModule.forRoot([NGXS_DATA_STORAGE_PLUGIN]) \n ]\n})\nexport class AppModule {} \n\n" /* NGXS_PERSISTENCE_CONTAINER */);
  }
}
const NGXS_DATA_STORAGE_PLUGIN = [NGXS_DATA_STORAGE_EXTENSION, NGXS_DATA_STORAGE_CONTAINER, NGXS_DATA_STORAGE_PREFIX, NGXS_DATA_STORAGE_DECODE_TYPE];

/**
 * Generated bundle index. Do not edit.
 */

export { DEFAULT_KEY_PREFIX, NGXS_DATA_STORAGE_CONTAINER, NGXS_DATA_STORAGE_CONTAINER_TOKEN, NGXS_DATA_STORAGE_DECODE_TYPE, NGXS_DATA_STORAGE_DECODE_TYPE_TOKEN, NGXS_DATA_STORAGE_EXTENSION, NGXS_DATA_STORAGE_PLUGIN, NGXS_DATA_STORAGE_PREFIX, NGXS_DATA_STORAGE_PREFIX_TOKEN, NgxsDataStorageContainer, NgxsDataStoragePlugin, STORAGE_TTL_DELAY, ensurePath, ensureProviders, isStorageEvent, registerStorageProviders, storageUseFactory };
