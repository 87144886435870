import * as i0 from '@angular/core';
import { Injectable, isDevMode } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { __decorate, __metadata, __param } from 'tslib';
import { Computed, DataAction, Payload } from '@angular-ru/ngxs/decorators';
import { ensureSnapshot, ensureDataStateContext } from '@angular-ru/ngxs/internals';
import { sortByDesc, sortByAsc } from '@angular-ru/cdk/object';
import { PrimaryKey, SortOrderType } from '@angular-ru/cdk/typings';
import { isNil } from '@angular-ru/cdk/utils';
import { map } from 'rxjs/operators';
class AbstractRepository {
  constructor() {
    this._dirty = true;
    this.browserStorageEvents$ = new Subject();
    this.isInitialised = false;
    this.isBootstrapped = false;
  }
  get dirty() {
    return this._dirty;
  }
  set dirty(value) {
    this._dirty = value;
  }
  ngxsOnChanges(_) {
    if (this.dirty && this.isBootstrapped) {
      this.dirty = false;
      this.ngxsDataDoCheck?.();
    }
  }
  ngxsOnInit() {
    this.isInitialised = true;
  }
  ngxsAfterBootstrap() {
    this.isBootstrapped = true;
    if (this.dirty) {
      this.dirty = false;
      this.ngxsDataDoCheck?.();
    }
  }
  markAsDirtyAfterReset() {
    this.dirty = true;
    this.ngxsDataAfterReset?.();
  }
}
/** @nocollapse */
AbstractRepository.ɵfac = function AbstractRepository_Factory(t) {
  return new (t || AbstractRepository)();
};
/** @nocollapse */
AbstractRepository.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AbstractRepository,
  factory: AbstractRepository.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractRepository, [{
    type: Injectable
  }], null, null);
})();
class AbstractNgxsDataRepository extends AbstractRepository {
  get snapshot() {
    return ensureSnapshot(this.getState());
  }
  get ctx() {
    return ensureDataStateContext(this.context);
  }
  patchState(value) {
    this.ctx.patchState(value);
  }
  setState(stateValue) {
    this.ctx.setState(stateValue);
  }
  reset() {
    this.ctx.setState(this.initialState);
    this.markAsDirtyAfterReset();
  }
  getState() {
    return this.ctx.getState();
  }
  dispatch(actions) {
    return this.ctx.dispatch(actions);
  }
}
/** @nocollapse */
AbstractNgxsDataRepository.ɵfac = /* @__PURE__ */(() => {
  let ɵAbstractNgxsDataRepository_BaseFactory;
  return function AbstractNgxsDataRepository_Factory(t) {
    return (ɵAbstractNgxsDataRepository_BaseFactory || (ɵAbstractNgxsDataRepository_BaseFactory = i0.ɵɵgetInheritedFactory(AbstractNgxsDataRepository)))(t || AbstractNgxsDataRepository);
  };
})();
/** @nocollapse */
AbstractNgxsDataRepository.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AbstractNgxsDataRepository,
  factory: AbstractNgxsDataRepository.ɵfac
});
__decorate([Computed(), __metadata("design:type", Object), __metadata("design:paramtypes", [])], AbstractNgxsDataRepository.prototype, "snapshot", null);
__decorate([DataAction(), __param(0, Payload('patchValue')), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], AbstractNgxsDataRepository.prototype, "patchState", null);
__decorate([DataAction(), __param(0, Payload('stateValue')), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], AbstractNgxsDataRepository.prototype, "setState", null);
__decorate([DataAction(), __metadata("design:type", Function), __metadata("design:paramtypes", []), __metadata("design:returntype", void 0)], AbstractNgxsDataRepository.prototype, "reset", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractNgxsDataRepository, [{
    type: Injectable
  }], null, {
    snapshot: [],
    patchState: [],
    setState: [],
    reset: []
  });
})();
class AbstractNgxsDataEntityCollectionsRepository extends AbstractRepository {
  constructor() {
    super(...arguments);
    this.primaryKey = PrimaryKey.ID;
    this.comparator = null;
  }
  get snapshot() {
    return ensureSnapshot(this.getState());
  }
  get ids() {
    return this.snapshot.ids;
  }
  get entities() {
    return this.snapshot.entities;
  }
  get entitiesArray() {
    const snapshot = this.snapshot;
    return snapshot.ids.map(id => snapshot.entities[id]);
  }
  get ids$() {
    return this.state$.pipe(map(value => value.ids));
  }
  get entities$() {
    return this.state$.pipe(map(value => value.entities));
  }
  get entitiesArray$() {
    return this.state$.pipe(map(value => value.ids.map(id => value.entities[id])));
  }
  get ctx() {
    return ensureDataStateContext(this.context);
  }
  reset() {
    this.setEntitiesState(this.initialState);
    this.markAsDirtyAfterReset();
  }
  addOne(entity) {
    this.addEntityOne(entity);
  }
  addMany(entities) {
    this.addEntitiesMany(entities);
  }
  setOne(entity) {
    this.setEntityOne(entity);
  }
  setMany(entities) {
    this.setEntitiesMany(entities);
  }
  setAll(entities) {
    this.setEntitiesAll(entities);
  }
  updateOne(update) {
    this.updateEntitiesMany([update]);
  }
  updateMany(updates) {
    this.updateEntitiesMany(updates);
  }
  upsertOne(entity) {
    this.upsertEntitiesMany([entity]);
  }
  upsertMany(entities) {
    this.upsertEntitiesMany(entities);
  }
  removeOne(id) {
    this.removeEntitiesMany([id]);
  }
  removeMany(ids) {
    this.removeEntitiesMany(ids);
  }
  removeByEntity(entity) {
    const id = this.selectId(entity);
    this.removeEntitiesMany([id]);
  }
  removeByEntities(entities) {
    const ids = [];
    for (const entity of entities) {
      const id = this.selectId(entity);
      ids.push(id);
    }
    this.removeEntitiesMany(ids);
  }
  removeAll() {
    this.setAll([]);
  }
  sort(comparator) {
    this.comparator = comparator ?? this.comparator;
    if (isNil(this.comparator)) {
      console.warn("You must set the compare function before sorting." /* NGXS_COMPARE */);
      return;
    }
    this.setEntitiesState(this.getState());
  }
  patchState(value) {
    this.ctx.patchState(value);
  }
  setComparator(comparator) {
    this.comparator = comparator;
    return this;
  }
  dispatch(actions) {
    return this.ctx.dispatch(actions);
  }
  getState() {
    return this.ctx.getState();
  }
  selectId(entity) {
    return entity?.[this.primaryKey];
  }
  selectOne(id) {
    return this.snapshot.entities[id] ?? null;
  }
  selectAll() {
    const state = this.getState();
    return state.ids.map(id => state.entities[id]);
  }
  addEntityOne(entity) {
    const state = this.getState();
    const id = this.selectIdValue(entity);
    if (id in state.entities) {
      return;
    }
    this.setEntitiesState({
      ...state,
      ids: [...state.ids, id],
      entities: {
        ...state.entities,
        [id]: entity
      }
    });
  }
  addEntitiesMany(entities) {
    const state = this.getState();
    const dictionary = {};
    const ids = [];
    for (const entity of entities) {
      const id = this.selectIdValue(entity);
      if (id in state.entities || id in dictionary) {
        continue;
      }
      ids.push(id);
      dictionary[id] = entity;
    }
    if (ids.length > 0) {
      this.setEntitiesState({
        ...state,
        ids: [...state.ids, ...ids],
        entities: {
          ...state.entities,
          ...dictionary
        }
      });
    }
  }
  setEntitiesAll(entities) {
    const state = this.getState();
    const dictionary = {};
    const ids = [];
    for (const entity of entities) {
      const id = this.selectIdValue(entity);
      if (id in dictionary) {
        continue;
      }
      ids.push(id);
      dictionary[id] = entity;
    }
    this.setEntitiesState({
      ...state,
      ids,
      entities: dictionary
    });
  }
  setEntityOne(entity) {
    const state = this.getState();
    const id = this.selectIdValue(entity);
    if (id in state.entities) {
      this.setEntitiesState({
        ...state,
        entities: {
          ...state.entities,
          [id]: entity
        }
      });
    } else {
      this.setEntitiesState({
        ...state,
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [id]: entity
        }
      });
    }
  }
  setEntitiesMany(entities) {
    for (const entity of entities) {
      this.setEntityOne(entity);
    }
  }
  // eslint-disable-next-line max-lines-per-function
  updateEntitiesMany(updates) {
    const state = this.getState();
    const newUpdates = updates.filter(update => update.id in state.entities);
    if (newUpdates.length === 0) {
      return;
    }
    const keys = this.generateKeyMap(state);
    const entities = {
      ...state.entities
    };
    for (const update of newUpdates) {
      const updated = this.updateOrigin(entities, update);
      const newId = this.selectIdValue(updated);
      if (newId !== update.id) {
        delete keys[update.id];
        delete entities[update.id];
      }
      keys[update.id] = newId;
      entities[newId] = updated;
    }
    this.setEntitiesState({
      ...state,
      ids: state.ids.map(id => keys[id] ?? id),
      entities
    });
  }
  upsertEntitiesMany(entities) {
    const state = this.getState();
    const updates = [];
    const added = [];
    for (const entity of entities) {
      const id = this.selectIdValue(entity);
      if (id in state.entities) {
        updates.push({
          id,
          changes: entity
        });
      } else {
        added.push(entity);
      }
    }
    this.updateMany(updates);
    this.addMany(added);
  }
  removeEntitiesMany(ids) {
    const state = this.getState();
    const keys = this.generateKeyMap(state);
    const entities = {
      ...state.entities
    };
    for (const id of ids) {
      if (id in entities) {
        delete keys[id];
        delete entities[id];
      }
    }
    this.setEntitiesState({
      ...state,
      ids: state.ids.filter(id => id in keys),
      entities
    });
  }
  setEntitiesState(state) {
    const ids = this.sortKeysByComparator(state.ids, state.entities);
    this.ctx.patchState({
      ...state,
      ids,
      entities: state.entities
    });
  }
  sortKeysByComparator(originalIds, entities) {
    if (isNil(this.comparator)) {
      return originalIds;
    }
    const ids = originalIds.slice();
    const comparator = this.comparator;
    if (typeof comparator === 'function') {
      return ids.sort((a, b) => comparator(entities[a], entities[b]));
    }
    return this.sortByComparatorOptions(ids, comparator, entities);
  }
  sortByComparatorOptions(ids, comparator, entities) {
    switch (comparator?.sortByOrder) {
      case SortOrderType.ASC:
        return ids.sort((a, b) => sortByAsc(comparator?.sortBy, entities[a], entities[b]));
      case SortOrderType.DESC:
        return ids.sort((a, b) => sortByDesc(comparator?.sortBy, entities[a], entities[b]));
      default:
        if (isDevMode()) {
          console.warn(`Invalid --> { sortByOrder: "${comparator?.sortByOrder}" } not supported!`);
        }
        return ids;
    }
  }
  generateKeyMap(state) {
    return state.ids.reduce((keyDictionary, id) => {
      keyDictionary[id] = id;
      return keyDictionary;
    }, {});
  }
  updateOrigin(entities, update) {
    const original = entities[update.id];
    return {
      ...original,
      ...update.changes
    };
  }
  selectIdValue(entity) {
    const id = this.selectId(entity);
    const invalidId = isNil(id) && isDevMode();
    if (invalidId) {
      console.warn(`The entity passed to the 'selectId' implementation returned ${id}.`, `You should probably provide your own 'selectId' implementation.`, 'The entity that was passed:', entity, 'The current `selectId` implementation: (entity: V): K => entity.id');
    }
    return id;
  }
}
/** @nocollapse */
AbstractNgxsDataEntityCollectionsRepository.ɵfac = /* @__PURE__ */(() => {
  let ɵAbstractNgxsDataEntityCollectionsRepository_BaseFactory;
  return function AbstractNgxsDataEntityCollectionsRepository_Factory(t) {
    return (ɵAbstractNgxsDataEntityCollectionsRepository_BaseFactory || (ɵAbstractNgxsDataEntityCollectionsRepository_BaseFactory = i0.ɵɵgetInheritedFactory(AbstractNgxsDataEntityCollectionsRepository)))(t || AbstractNgxsDataEntityCollectionsRepository);
  };
})();
/** @nocollapse */
AbstractNgxsDataEntityCollectionsRepository.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AbstractNgxsDataEntityCollectionsRepository,
  factory: AbstractNgxsDataEntityCollectionsRepository.ɵfac
});
__decorate([Computed(), __metadata("design:type", Object), __metadata("design:paramtypes", [])], AbstractNgxsDataEntityCollectionsRepository.prototype, "snapshot", null);
__decorate([Computed(), __metadata("design:type", Array), __metadata("design:paramtypes", [])], AbstractNgxsDataEntityCollectionsRepository.prototype, "ids", null);
__decorate([Computed(), __metadata("design:type", Object), __metadata("design:paramtypes", [])], AbstractNgxsDataEntityCollectionsRepository.prototype, "entities", null);
__decorate([Computed(), __metadata("design:type", Array), __metadata("design:paramtypes", [])], AbstractNgxsDataEntityCollectionsRepository.prototype, "entitiesArray", null);
__decorate([Computed(), __metadata("design:type", Observable), __metadata("design:paramtypes", [])], AbstractNgxsDataEntityCollectionsRepository.prototype, "ids$", null);
__decorate([Computed(), __metadata("design:type", Observable), __metadata("design:paramtypes", [])], AbstractNgxsDataEntityCollectionsRepository.prototype, "entities$", null);
__decorate([Computed(), __metadata("design:type", Observable), __metadata("design:paramtypes", [])], AbstractNgxsDataEntityCollectionsRepository.prototype, "entitiesArray$", null);
__decorate([DataAction(), __metadata("design:type", Function), __metadata("design:paramtypes", []), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "reset", null);
__decorate([DataAction(), __param(0, Payload('entity')), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "addOne", null);
__decorate([DataAction(), __param(0, Payload('entities')), __metadata("design:type", Function), __metadata("design:paramtypes", [Array]), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "addMany", null);
__decorate([DataAction(), __param(0, Payload('entity')), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "setOne", null);
__decorate([DataAction(), __param(0, Payload('entities')), __metadata("design:type", Function), __metadata("design:paramtypes", [Array]), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "setMany", null);
__decorate([DataAction(), __param(0, Payload('entities')), __metadata("design:type", Function), __metadata("design:paramtypes", [Array]), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "setAll", null);
__decorate([DataAction(), __param(0, Payload('update')), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "updateOne", null);
__decorate([DataAction(), __param(0, Payload('updates')), __metadata("design:type", Function), __metadata("design:paramtypes", [Array]), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "updateMany", null);
__decorate([DataAction(), __param(0, Payload('entity')), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "upsertOne", null);
__decorate([DataAction(), __param(0, Payload('entities')), __metadata("design:type", Function), __metadata("design:paramtypes", [Array]), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "upsertMany", null);
__decorate([DataAction(), __param(0, Payload('id')), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "removeOne", null);
__decorate([DataAction(), __param(0, Payload('ids')), __metadata("design:type", Function), __metadata("design:paramtypes", [Array]), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "removeMany", null);
__decorate([DataAction(), __param(0, Payload('entity')), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "removeByEntity", null);
__decorate([DataAction(), __param(0, Payload('entities')), __metadata("design:type", Function), __metadata("design:paramtypes", [Array]), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "removeByEntities", null);
__decorate([DataAction(), __metadata("design:type", Function), __metadata("design:paramtypes", []), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "removeAll", null);
__decorate([DataAction(), __param(0, Payload('comparator')), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "sort", null);
__decorate([DataAction(), __param(0, Payload('patchValue')), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], AbstractNgxsDataEntityCollectionsRepository.prototype, "patchState", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractNgxsDataEntityCollectionsRepository, [{
    type: Injectable
  }], null, {
    snapshot: [],
    ids: [],
    entities: [],
    entitiesArray: [],
    ids$: [],
    entities$: [],
    entitiesArray$: [],
    reset: [],
    addOne: [],
    addMany: [],
    setOne: [],
    setMany: [],
    setAll: [],
    updateOne: [],
    updateMany: [],
    upsertOne: [],
    upsertMany: [],
    removeOne: [],
    removeMany: [],
    removeByEntity: [],
    removeByEntities: [],
    removeAll: [],
    sort: [],
    patchState: []
  });
})();
class AbstractNgxsImmutableDataRepository extends AbstractRepository {
  get snapshot() {
    return ensureSnapshot(this.getState());
  }
  get ctx() {
    return ensureDataStateContext(this.context);
  }
  patchState(value) {
    this.ctx.patchState(value);
  }
  setState(stateValue) {
    this.ctx.setState(stateValue);
  }
  reset() {
    this.ctx.setState(this.initialState);
    this.markAsDirtyAfterReset();
  }
  getState() {
    return this.ctx.getState();
  }
  dispatch(actions) {
    return this.ctx.dispatch(actions);
  }
}
/** @nocollapse */
AbstractNgxsImmutableDataRepository.ɵfac = /* @__PURE__ */(() => {
  let ɵAbstractNgxsImmutableDataRepository_BaseFactory;
  return function AbstractNgxsImmutableDataRepository_Factory(t) {
    return (ɵAbstractNgxsImmutableDataRepository_BaseFactory || (ɵAbstractNgxsImmutableDataRepository_BaseFactory = i0.ɵɵgetInheritedFactory(AbstractNgxsImmutableDataRepository)))(t || AbstractNgxsImmutableDataRepository);
  };
})();
/** @nocollapse */
AbstractNgxsImmutableDataRepository.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AbstractNgxsImmutableDataRepository,
  factory: AbstractNgxsImmutableDataRepository.ɵfac
});
__decorate([Computed(), __metadata("design:type", Object), __metadata("design:paramtypes", [])], AbstractNgxsImmutableDataRepository.prototype, "snapshot", null);
__decorate([DataAction(), __param(0, Payload('patchValue')), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], AbstractNgxsImmutableDataRepository.prototype, "patchState", null);
__decorate([DataAction(), __param(0, Payload('stateValue')), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", void 0)], AbstractNgxsImmutableDataRepository.prototype, "setState", null);
__decorate([DataAction(), __metadata("design:type", Function), __metadata("design:paramtypes", []), __metadata("design:returntype", void 0)], AbstractNgxsImmutableDataRepository.prototype, "reset", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractNgxsImmutableDataRepository, [{
    type: Injectable
  }], null, {
    snapshot: [],
    patchState: [],
    setState: [],
    reset: []
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractRepository as NgxsAbstractDataRepository, AbstractNgxsDataEntityCollectionsRepository as NgxsDataEntityCollectionsRepository, AbstractNgxsDataRepository as NgxsDataRepository, AbstractNgxsImmutableDataRepository as NgxsImmutableDataRepository };
