import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  private logoutS = new Subject<any>();

  get logout$(): Subject<any> {
    return this.logoutS;
  }

  constructor() {}

  logout(): void {
    this.logoutS.next(null);
  }
}
