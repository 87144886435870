// eslint-disable-next-line no-restricted-syntax
var KeyboardKeys;
(function (KeyboardKeys) {
    KeyboardKeys["ARROW_DOWN"] = "ArrowDown";
    KeyboardKeys["ARROW_LEFT"] = "ArrowLeft";
    KeyboardKeys["ARROW_RIGHT"] = "ArrowRight";
    KeyboardKeys["ARROW_UP"] = "ArrowUp";
    KeyboardKeys["SHIFT"] = "Shift";
    KeyboardKeys["BACKSPACE"] = "Backspace";
    KeyboardKeys["CAPS_LOCK"] = "CapsLock";
})(KeyboardKeys || (KeyboardKeys = {}));

// eslint-disable-next-line no-restricted-syntax
var PrimaryKey;
(function (PrimaryKey) {
    PrimaryKey["ID"] = "id";
})(PrimaryKey || (PrimaryKey = {}));

// eslint-disable-next-line
var SortOrderType;
(function (SortOrderType) {
    SortOrderType["ASC"] = "asc";
    SortOrderType["DESC"] = "desc";
})(SortOrderType || (SortOrderType = {}));

/**
 * Generated bundle index. Do not edit.
 */

export { KeyboardKeys, PrimaryKey, SortOrderType };

