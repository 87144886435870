import { isNotNil } from '@angular-ru/cdk/utils';

function $args(fn) {
    return (String(fn) // fast to string
        .replace(/\/\/.*$/gm, '') // strip single-line comments
        .replace(/\s+/g, '') // strip white space
        .replace(/\/\*[^*/]*\*\//g, '') // strip multi-line comments
        .split('){', 1)?.[0]
        ?.replace(/^[^(]*\(/, '') // extract the parameters
        .replace(/=[^,]+/g, '') // strip any ES6 defaults
        .split(',')
        .filter(Boolean) ?? []); // split & filter [""]
}

function hasConstructor(object) {
    return isNotNil(object?.prototype) && isNotNil(object?.prototype.constructor.name);
}

function isFunctionLike(fn) {
    return typeof fn === 'function';
}

function typeofType(classType) {
    return () => classType;
}

/**
 * Generated bundle index. Do not edit.
 */

export { $args, hasConstructor, isFunctionLike, typeofType };

